<template>
  <div class="container">
    <section>
      <span>Ooops...</span>
      <p class="desc">
        Esta rota não está disponível para dispositivos móveis.
      </p>

      <div>
        <BaseButton @click="backHome" variant="primary"
          >Voltar à página principal</BaseButton
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "IsMobile",

  methods: {
    backHome() {
      this.$router.push("/dynamicRoute/home");
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999999;

  section {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 20px;

    span {
      color: var(--gray05);
      margin-bottom: 0;
      font-size: 37px;
      font-weight: 600;
    }

    .desc {
      font-size: 15px;
      margin-top: 10px;
      color: var(--gray05);
      font-weight: 500;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
  }
}
</style>
